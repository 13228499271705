var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"concernedContactDetail"},[_c('app-header'),(_vm.workingObject)?_c('boxes',{attrs:{"options":{
      title: 'label.concernedContacts',
      bg:'gray',
      type:'concernedContactBox',
      businesskey:'concernedContact',
      modal:'concernedContactModal',
      data: _vm.concernedContacts,
      projectId: _vm.workingObject.PrID,
      buttons: ['addButton'],
      isfieldRequest: false,
      enableBoxLink: true
    }}}):_vm._e(),(_vm.workingObject)?_c('editors',{attrs:{"screen":"frmConcernedContactDetails"}}):_vm._e(),_c('nv-loading',{attrs:{"show":_vm.loading}}),_c('app-footer',{attrs:{"saveComponentDataFunction":_vm.save}}),_c('ExportProjectPopup',{attrs:{"exportPopupOpen":_vm.exportPopupOpen},on:{"closeButtonClicked":_vm.toggleExportPopup}})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }