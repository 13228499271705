<template>
  <div class="concernedContactDetail">
    <app-header/>
    <boxes
      v-if="workingObject"
      :options="{
        title: 'label.concernedContacts',
        bg:'gray',
        type:'concernedContactBox',
        businesskey:'concernedContact',
        modal:'concernedContactModal',
        data: concernedContacts,
        projectId: workingObject.PrID,
        buttons: ['addButton'],
        isfieldRequest: false,
        enableBoxLink: true
      }">
    </boxes>

    <editors v-if="workingObject" screen='frmConcernedContactDetails'></editors>
    <nv-loading :show="loading"></nv-loading>
    <app-footer :saveComponentDataFunction="save" />

    <ExportProjectPopup :exportPopupOpen="exportPopupOpen" @closeButtonClicked="toggleExportPopup"/>
  </div>
</template>

<script>
import $ from 'jquery'
import _ from 'lodash'
import AppHeader from '@/components/_shared/header'
import AppFooter from '@/components/_shared/footer'
import config from '@/configurations/config.js'
import nvLoading from '@/components/_shared/loading.vue'
import Editors from '@/components/editors.vue'
import boxes from '@/components/boxes.vue'
import Popup from '@/utils/popup'
import { CONCERNED_CONTACT_TYPE, codeListIDMapping, CONTACT_PERSON_FUNCTIONS_CODE_LIST, CONCERNED_COMPANY_TYPES_CODE_LIST } from '@/vuex/constants'
import workingObjectUtils from '@/utils/workingObjectUtils'
import { ConcernedContactsService } from '@/services/DataWS/concernedContactsService'
import { CodeListService } from '@/services/DataWS/codeListService'
import ExportProjectPopup from '@/components/export/exportProjectPopup.vue'

const concernedContactsService = new ConcernedContactsService()
const codeListService = new CodeListService()

let popup = Popup[config.platform].default
var self

/**
   * The details of one specific concerned contact.
   */
export default {
  name: 'concernedContact',
  components: {
    AppHeader,
    AppFooter,
    nvLoading,
    Editors,
    boxes,
    ExportProjectPopup
  },
  data () {
    return {
      loading: true,
      PrID: null,
      exportPopupOpen: false
    }
  },
  computed: {
    concernedContacts: {
      get () {
        return this.$store.state.concernedContacts
      },
      set (value) {
        this.$store.commit('setConcernedContacts', value)
      }
    },
    workingObject: {
      get () {
        return this.$store.state.workingObject
      },
      set (value) {
        this.$store.commit('setWorkingObject', value)
      }
    },
    workingObjectType: {
      get () {
        return this.$store.state.workingObjectType
      },
      set (value) {
        this.$store.commit('setWorkingObjectType', value)
      }
    },
    workingObjectIndex: {
      get () {
        return this.$store.state.workingObjectIndex
      },
      set (value) {
        this.$store.commit('setWorkingObjectIndex', value)
      }
    },
    currentProject: {
      get () {
        return this.$store.state.prTypeCode
      }
    },
    [CONTACT_PERSON_FUNCTIONS_CODE_LIST]: {
      get () {
        return this.$store.state.codeLists[CONTACT_PERSON_FUNCTIONS_CODE_LIST]
      },
      set (value) {
        this.$store.commit('setCodeList', {
          list: CONTACT_PERSON_FUNCTIONS_CODE_LIST,
          value: value
        })
      }
    },
    [CONCERNED_COMPANY_TYPES_CODE_LIST]: {
      get () {
        return this.$store.state.codeLists[CONCERNED_COMPANY_TYPES_CODE_LIST]
      },
      set (value) {
        this.$store.commit('setCodeList', {
          list: CONCERNED_COMPANY_TYPES_CODE_LIST,
          value: value
        })
      }
    }
  },
  watch: {
    // on route change, this is NOT called for fresh loads. fresh loads are handled in created()
    '$route': {
      handler: function () {
        // update active concerned contact
        this.setActiveConcernedContact()
      },
      deep: true
    }
  },
  created () {
    self = this

    this.PrID = this.$route.params.projectId

    let tasks = []

    tasks.push(codeListService.getCodeListByCategoryIDOrCode(codeListIDMapping[CONCERNED_COMPANY_TYPES_CODE_LIST]))
    tasks.push(codeListService.getCodeListByCategoryIDOrCode(codeListIDMapping[CONTACT_PERSON_FUNCTIONS_CODE_LIST]))

    // if no concerned contacts are loaded, then load
    if (!(_.isArray(this.concernedContacts) && this.concernedContacts.length)) {
      tasks.push(concernedContactsService.getConcernedContacts(this.PrID))
    }

    window.Promise.all(tasks)
      .then(([concernedCompanyTypes, contactPersonFunctions, concernedContactsRes]) => {
        // set codelists before setting concernedcontacts as they are currently used by the boxes
        self[CONCERNED_COMPANY_TYPES_CODE_LIST] = concernedCompanyTypes
        self[CONTACT_PERSON_FUNCTIONS_CODE_LIST] = contactPersonFunctions

        if (concernedContactsRes) {
          self.concernedContacts = concernedContactsRes.tblConcernedCompanies
        }

        // set active finishing
        self.setActiveConcernedContact()

        // hide loading indicator
        self.loading = false
      })
      .catch(err => {
        self.loading = false
        console.log(err)
      })
  },
  methods: {
    setActiveConcernedContact () {
      this.workingObjectIndex = _.findIndex(this.concernedContacts, { CcGUID: this.$route.params.CcGUID })

      this.concernedContacts.forEach(ed => (ed.isActive = false))

      this.workingObject = this.concernedContacts[this.workingObjectIndex]
      this.workingObject.isActive = true
      this.workingObjectType = CONCERNED_CONTACT_TYPE

      this.setState('FiFrom', 'FiTo')
    },
    setState (from, to) {
      /* var titleParts = []
      if (this.workingObject['FiTo'] !== undefined) {
        titleParts.push(this.workingObject['FiTo'])
      }
      if (this.workingObject['FiFrom'] !== undefined) {
        titleParts.push(this.workingObject['FiFrom'])
      }
      // this.$store.dispatch('setActiveTitle', titleParts.join('-'))
      this.$store.dispatch('setPrevTitle', (this.currentProject || {}).PrCode) */
    },
    save () {
      this.loading = true
      return workingObjectUtils.pushWorkingObjectToDataService()
        .then(res => {
          self.loading = false
          // update concerned contacts with response from server
          self.concernedContacts = res

          return true
        })
        .catch(err => {
          self.loading = false
          self.handlerError(err)
        })
    },
    deleteConcernedContact () {
      let modalDelete
      modalDelete = $('#modalDeleteConcernedContact')
      modalDelete.modal('toggle')
    },
    handlerError (err) {
      popup.popup(err.body)
    },
    async toggleExportPopup() {
      if (!this.exportPopupOpen && this.$store.state.workingObjectChanged) {
        await this.save()
      }
      this.exportPopupOpen = !this.exportPopupOpen
    }
  }
}
</script>
<style>
    @media (max-width: 640px) {
        .concernedContactDetail .edit-panel .input-group.input-left {
            width: 100%;
        }
    }

    @media (min-width: 768px) {
        .concernedContactDetail .editor-panel {
            width: calc(100vw - 410px);
        }
    }
</style>
